img{width: 100%;}
.fontSize12{
  font-size: .75rem;  
  width: 85%;
  margin: 0 auto;
  display: inline-block;
  line-height: 18px
}
#Mobile{
  padding-bottom: 1.25rem;
  font-size:0.75rem;
  width:100vw;
  min-height:100vh;
  background-color: #0A0A0A;
  background-image: url("@/assets/mobile/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
  overflow-y: scroll;
}

.mobile-sameTit{
  height:1.75rem;
  line-height:1.75rem;
  font-size: 1.375rem;
  font-weight: 700;
  font-family: 'mySpaceGrotesk', sans-serif;
  text-align: center;
}

.mobile-lineImg{
  position: relative;
  margin: 3.125rem auto 1.875rem;
  padding-bottom: 3rem;
}
.mobile-lineImg::after{
  content: '';
  width: 3.125rem;
  height: 0.3125rem;
  background-image: url("@/assets/mobile/line.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
}


.mobile-navHeader{
  width: 100%;
  height: 3.625rem;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

}
.mobile-navBtn{
  width: 2.25rem;
  height: 2.25rem;
  cursor: pointer;
  display: block;
  position: absolute;
  right: 0.625rem;
  top: 0.6875rem;
}
.mobile-newNav{
  width: 100%;
  background-image: url("@/assets/mobile/navbg.png");
  background-size: 100% 100%;
  display: none;
  padding-top: 1.25rem;
  margin-top: 3.625rem;

}
.mobile-newNav a{
  display: block;
  height:3.125rem;
  line-height:3.125rem;
  text-align: center;
  cursor: pointer;
}
.mobile-newNav a:hover{
  background: rgba(255, 255, 255, 0.1);

}
.mobile-homeBox{
  width: 100%;
  padding: 13rem 1.625rem 2rem;
  text-align: center;
}
.mobile-tit-1{
  width: 90%;
  margin: 0 auto;
  font-size:1.75rem;
  line-height: 2.2344rem;
  margin-bottom: 1.25rem;
}
.mobile-txt-1{
  width: 90%;
  margin: 0 auto;
  display:inline-block;
  margin: 0 auto 1.56rem;
  line-height:16.2px;
}
.mobile-home_2,.mobile-home_1{
  width: 100%;
}
.mobile-home_2{
  width:70%;
}
.mobile-mySwiper{
  margin:25px auto 0;
}
.mobile-mySwiper .mobile-slide{
  width: 100%;
}
.mobile-mySwiper .mobile-slide img{
  width: 90%;
}
.mobile-newsEvents{
  background-image: url("@/assets/mobile/swBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: .5rem;
}
.mobile-newsEvents .mobile-lineImg{
  margin: 0 auto;
}
.mobile-newsEvents .mobile-swiperBox .mobile-mySwiper .slide{
  width: 100%;
}
.mobile-newsEvents .mobile-swiperBox .mobile-mySwiper .slide img{
  width: 100%;
}
.mobile-newsEvents .mobile-swiperBox  .swiper-wrapper {
  /*之前是ease-out*/
  -moz-transition-timing-function: linear !important;
  -ms-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  margin: 0 auto !important;
}
.mobile-partBox{
  padding: 0 1.6rem;
}
.mobile-teaching,.mobile-training{
  width: 100%;
  height: 38rem;
  display: inline-block;
  background-image:  url("@/assets/mobile/teaching.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 15px 0 0;
}
.mobile-training{
  background-image:  url("@/assets/mobile/training.png");
}
.mobile-contentTxt{
  text-align: left;
  padding: 0 25px;
  max-height: 230px;
  overflow-y:auto;
}
.mobile-teaching  h1,.mobile-training h1{
  font-size: .9375rem;
  font-weight: 700;
  line-height: 1.1875rem;
  padding: 10px 25px ;
  font-family: 'mySpaceGrotesk', sans-serif;
}
.mobile-contentTxt p{
  font-size: .75rem;
  line-height: .9688rem;
  font-family: 'myWorkSans', sans-serif;
  font-size: 12px;
  margin-bottom: 12px;
}
.mobile-contentTxt1 p{
  padding-left:1.25rem;
  position: relative;
  margin-bottom: 15px;
}
.mobile-contentTxt1 p::before{
  content: '';
  width: .625rem;
  height:.625rem;
  background-image: url("@/assets/mobile/icon_1.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top:0.03rem
}
.mobile-contentTxt .mobile-title{
  font-size: .75rem;
  line-height: .975rem;
  padding-left: 0;
  padding: .3125rem 0;
  margin: 0;
  margin-bottom: 15px;
}
.mobile-contentTxt .mobile-title::before{content: none;}
.mobile-contentTxt .mobile-title a{ 
 
  color: #66FC4E;
}
.mobile-scientistsBox li {
  display: flex;
  align-items: flex-start;
  margin: 2rem auto;
  border: .0625rem solid #FFFFFF33;
  border-radius: .75rem;
}
.mobile-scientistImg{
  width: 60%;
}
.mobile-peopleInfo{
  width: 40%;
  padding: 1rem;
  font-size: .75rem;
  line-height: 1rem;
  color: #FFFFFFCC;
}
.mobile-partnerImg,.mobile-supportImg{
  width: 50%;
  margin:  0 auto;
  display: block;
}
.mobile-urlBox{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.875rem auto;
  width: 50%;
  text-align: center;
}
.mobile-urlBox img{
  width: 60%;
}
#mobile-footerBox{
  padding: 0 1.6rem;
  margin: 5rem auto;
  opacity: 1;
}
.mobile-footContent{
  text-align: center;
}
.mobile-footContent p{
  margin-bottom: 2rem
}
.mobile-footContent img{
  width: 26px;
  vertical-align: middle;
}

.mobile-mySwiper .swiper-pagination-bullet{
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  background: #B3B3C0B2;
  margin-right:10px ;
}
.mobile-mySwiper .swiper-pagination-bullet-active{
  background: #7441E8;
}
.mobile-part-2 .mobile-mySwiper:nth-child(6) .swiper-pagination{margin: 1.25rem auto;}
.mobile-part-2 .mobile-mySwiper:nth-child(3) .swiper-pagination{    position: relative;
  margin-top: -1rem;z-index: 88;}
.mobile-train-teach .swiper-pagination{text-align: center;}





