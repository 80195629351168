*{
    color: #fff;
    font-weight: 400;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
a{text-decoration:none;}
ul,li,ol{list-style-type:none;padding: 0;margin: 0;}
@font-face {
    font-family: 'myWorkSans';
    src:url('./assets/font/WorkSans-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'mySpaceGrotesk';
    src:url('./assets/font/SpaceGrotesk.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
.sgFont{font-family: 'mySpaceGrotesk', sans-serif;}
.wsFont{font-family: 'myWorkSans', sans-serif;}
body,html{
    background-color: #0A0A0A;
}