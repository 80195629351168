.sameTit{
    height: 74px;
    font-size: 58px;
    line-height: 74px;
    font-weight: 700;
    font-family: 'mySpaceGrotesk', sans-serif;
}
.mask{
    background-color: #0A0A0A;
    opacity: 0.86;
}

#app{
    width:100vw;
    min-height:100vh;
    background-color: #0A0A0A;
    background-image: url("@/assets/pc/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
    overflow-y: scroll;
}
.maxW1080{
  max-width: 1080px;
  width: 1080px;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: scroll;
  
}
.headerBox{
    cursor: pointer;
    width: 656px;
    height: 80px;
    line-height: 80px;
    margin: 0 auto;
    font-family: 'myWorkSans', sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}
.headerBox a{
    margin-right: 24px;
}

.headerFix{ 
    min-width: 1440px;
    width: 100%;
    padding: 0 297px;
   
    background:rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
   
}

.headerBox a:hover{transform: scale(1.05);}
.navHeader{
    position: relative;
    width: 100%;
    padding: 10px 0;
    font-family: 'myWorkSans', sans-serif;
    display: none;
    z-index: 999;

}
.navBtn{
    position: fixed;
    right: 10px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 999;
    
}
.newNav{
    position: fixed;
    top: 0;
    width: 100%;
   background-image: url("@/assets/pc/navbg.png");
   background-size: 100% 100%;
   display: none;
   padding-top: 50px;
  
  
}
.newNav a{
    display: block;
    height: 50px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
   
}
.newNav a:hover{
    background: rgba(255, 255, 255, 0.1);

}
/* home */
.homeBox{
    text-align: center;
    padding-top: 149px
}
.tit-1{
    font-size: 54px;
    line-height: 68.9px;
    opacity: 0;
}
.txt-1{
    display:inline-block;
    width: 900px;
    margin: 0 auto;
    line-height: 21.6px;
    opacity: 0;
    margin-top: 27px;
}
.homeImg-1{
    width: 959px;
    height: 435px;
    background-image: url("@/assets/pc/home_1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 47px auto 0;
    opacity: 0;
}
.homeImg-1 .img-0{
    width: 310px;
}
.homeImg-2{
    width: 100%;
    position: relative;
    opacity: 0;
    margin-top: 112px;
    text-align: center;
}
.homeImg-3{
    margin-top: 45px;
}

.lineImg{
    position: relative;
    text-align: center;
    opacity: 0;
}
.lineImg::after{
    content: '';
    width: 149px;
    height: 5px;
    background-image: url("@/assets/pc/line.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -40px;
}
.txt-3{
    display: block;
    width: 949px;
    padding: 82px 0 60px;
    opacity: 0;
   margin: 0 auto;
   font-size: 16px;
   line-height: 24px;
}
.txtBox{
    display: flex;
    align-items: flex-start;
    justify-content: space-between
}
.txt-4 span,.txt-5 span{
    display: block;
}
.txt-4 p,.txt-5 p{
   margin: 0;
   text-align: left;
   padding: 0 20px;
}
.img-5{
    width: 480px;
    height: 290px;
    display: inline-block;
    background-image:  url("@/assets/pc/home_7.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    opacity: 0;
}
.img-6{
    width: 480px;
    height: 240px;
    display: inline-block;
    background-image:  url("@/assets/pc/home_8.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    opacity: 0;
}
.txt-5 .img-6,.txt-4 .img-5{
    margin-bottom: 32px;
}

.img-5 p:nth-child(1),.img-6 p:nth-child(1){
    font-size: 20px;
    font-weight: 600;
    padding-top: 40px;
}
.img-5 p:nth-child(2),.img-6 p:nth-child(2){
    font-size: 14px;
    font-weight: 400;
    padding-top: 20px;
}

/* newsEvents */
.newsEvents{
    background-image: url("@/assets/pc/swBg.png");
    opacity: 0;
    margin-top: 232px;
}
.newsEvents .lineImg{opacity: 1;}
.swiperBox{
    width: 1920px;
    margin: 100px auto 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .3) 100%);
}
.mySwiper{
    margin-bottom: 30px;
}
.mySwiper .swiper-wrapper .swiper-slide {
    margin-bottom: 20px;
    border-radius: 12px;
    width: 375px;
    height: 168px;

}
.swiperImg{
    width: 375px;
}

.mySwiper .swiper-wrapper {
    /*之前是ease-out*/
    -moz-transition-timing-function: linear !important;
    -ms-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
    margin: 0 auto !important;
}

.part3{
    margin-top: 216px;
}
/* "train-teach */
.train-teach .flexBox{
    width: 90%;
    margin:200px auto 0;

}
.flexBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.teaching,.training{
    width: 480px;
    height: 640px;
    display: inline-block;
    background-image:  url("@/assets/pc/teaching.png");
    background-size: 480px 640px;
    background-repeat: no-repeat;
    opacity: 0;
    padding: 20px 0;
   
}
.training{
    background-image:  url("@/assets/pc/training.png");
    background-size:480px 636.5px;
}

.contentTxt{
    text-align: left;
    padding: 0 25px;
    max-height: 330px;
    overflow-y:auto;
}
.flexBox h1{
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    padding: 10px 25px 0;
    font-family: 'mySpaceGrotesk', sans-serif;
    margin-bottom: 16px;
}
.contentTxt p{
    font-size: 13px;
    line-height: 17px;
    font-family: 'myWorkSans', sans-serif;
    margin-bottom: 16px;
    
}
.contentTxt1 p{
    padding-left: 20px;
    position: relative;
    margin-bottom: 17px;
   
}
.contentTxt1 p::before{
    content: '';
    width: 16px;
    height: 16px;
    background-image: url("@/assets/pc/icon_1.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top:.5px
}
.contentTxt .title{
    font-size: 15px;
    line-height: 19.5px;
    padding-left: 0;
    margin: 0;
    margin-bottom: 15px;
}
.contentTxt .title::before{content: none;}
.contentTxt .title a{ 
   
    color: #66FC4E;
}
.training .contentTxt p{
    margin-bottom: 12px;
}

.people{
    margin-top: 180px;
    text-align: center;
}
.peopleImg-1{
    width: 999px;
    margin: 115px auto 0;
    display: block;
    opacity: 0;
}
.scientists{
    margin-top: 240px;
}
.scientistsImg {
    width: 990px;
    margin: 115px auto 0;
}
.student{margin-top: 170px;}
.researcher{
    width: 996px;
    margin-top: 115px;
    opacity: 0;
}
/* partner */
.partner{
    margin-top: 240px;
    text-align: center;
}
.partner-url{
    width: 772px;
    display: inline-block;
    margin-top: 115px;
}
.partner-url a{
    width: 240px;
    height: 116px;
    display: inline-block;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    opacity: 0;
}
.partner_0{
    background-image:  url("@/assets/pc/partner1.png");
}
.partner_1{
    background-image:  url("@/assets/pc/partner2.png");
}
.partner_2{
    background-image:  url("@/assets/pc/partner3.png");
}
.community{
    margin-top: 240px;
}
.support-url{
    width: 999px;
    display: inline-block;
    margin-top: 115px;
}
.support-url a{
    width: 240px;
    height: 116px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    opacity: 0;
}
.support_0{
    background-image:  url("@/assets/pc/support1.png");
}
.support_1{
    background-image:  url("@/assets/pc/support2.png");
}
.support_2{
    background-image:  url("@/assets/pc/support3.png");
}
.support_3{
    background-image:  url("@/assets/pc/support4.png");
}
.support_4{
    background-image:  url("@/assets/pc/support5.png");
}
.support_5{
    background-image:  url("@/assets/pc/support6.png");
}
.support_6{
    background-image:  url("@/assets/pc/support7.png");
}
.support_7{
    background-image:  url("@/assets/pc/support8.png");
}
#footerBox{
    width: 1226px;
    max-width: 1226px;
    margin: 178px auto 0;
    padding-bottom: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-family: 'myWorkSans', sans-serif;
    opacity: 0;
}
.urlBox{
    height: 36px;
}
.urlBox a{
    width: 34px;
    height: 34px;
 
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    cursor: pointer;
    margin-right: 40px;
}


.urlBox a:nth-child(1){
    background-image:  url("@/assets/pc/icon1.png");
}
.urlBox a:nth-child(2){
    background-image:  url("@/assets/pc/icon2.png");
}
.urlBox a:nth-child(3){
    background-image:  url("@/assets/pc/icon3.png");
}
.urlBox a:nth-child(4){
    background-image:  url("@/assets/pc/icon4.png");
}
.urlBox a:nth-child(5){
    background-image:  url("@/assets/pc/icon5.png");
}
.urlBox a:hover{
    transform: scale(1.09);
}
.footContent p:nth-child(1){
    color: #B7B1BE;
    line-height: 28px;
}
.footContent p:nth-child(2){
    line-height: 28px;
    font-size: 20px;
    font-weight: 500;
}
.footContent p:nth-child(2) img{
    width: 39px;
    vertical-align: middle;
}

@media screen and (max-width: 1400px) {
    .headerBox{
        display: none;
    }
    .navHeader{
        display: block;
    }
 
}
@media screen and (min-width: 1620px) {
    .swiperBox{
        width: 2400px;
    }
    .mySwiper .swiper-wrapper .swiper-slide {
        width: 30rem;
        height:15.5rem;
    }
    .swiperImg{
        width: 28rem;
    }
}
@media screen and (min-width: 1820px) {
    .objectives{width: 65%;}
}